<template>
  <v-card rounded="0">
    <v-container>
      <v-row>
        <v-col cols="12" lg="8">
          <h5 class="h5 my-6">I am a pastor because of my grandfather, a lifelong Orthodox believer, endorsed the
            Seventh-day Adventist Church.</h5>
          <h5 class="h5 my-4">As my grandfather was growing old, he spoke to my father in their home in Ratesti, a small
            village in Romania: “If you ever want to belong to the only true Christian church, you have to go to the
            Seventh-Day Adventist Church,” said my grandfather Gherase Dumitru</h5>
          <h5 class="h5 my-4">Those words planted a seed that led my father, Aurelian, to seek out the Adventist Church
            and be baptized in Communist-era Romania. As a result, I grew up in an Adventist home, and my brother and I
            are both Adventist Pastors.</h5>
          <h5 class="h5 my-4">But how did my grandfather know about Adventists?</h5>
          <h5 class="h5 my-4">The story goes back to the early 1960s in Ratesti, located about 85 miles (135 kilometers)
            north of Romania’s Capital, Bucharest. Only eight or so Adventists lived in the village, and the authorities
            ordered their arrest amid a crackdown on religion. A driver was called to transport the Adventists to jail by
            horse and cart</h5>
          <h5 class="h5 my-4">As the cart shook and swayed on a bumpy dirt road, the prisoners began to
            sing hymns in the back. The driver listened, surprised at the beautiful words
            about Jesus and His love. He thought, How can these Adventists sing with
            such joy when they are going to jail?</h5>
          <h5 class="h5 my-4">About a week later, the driver was called to take the village’s Orthodox
            priest to his church. The cart struck a pothole on route to the church, and
            the priest angrily unleashed a torrent of blasphemies</h5>
          <h5 class="h5 my-4">The driver listened in shock. He thought, Why is the priest cursing his God
            for a pothole as he goes to church? And why did those Adventists sing such
            beautiful songs of praise to Jesus as they went to jail?</h5>
          <h5 class="h5 my-4">The two incidents left a deep impression on the driver, and he determined
            to learn more about the Adventists. After a while, he was baptized into the
            Adventist Church. The driver was a good friend of my grandfather’s.</h5>
          <h5 class="h5 my-4">We often think that people are not interested in what we are doing, but
            people are always watching. A cart of Adventist prisoners didn’t know that
            a driver was listening in awe to their songs of praise and that their
            faithfulness would shape two generations of Adventists in my family.</h5>
          <h5 class="h5 my-4">Today, my wife Xenia and I serve in the Sudbury SDA Church, and we enjoy
            every moment we spend in this community. Recently, we have been blessed
            with a wonderful boy, Reinhart, and we believe God led our family to this
            city for a great work.</h5>
          <h5 class="h5 my-4">Pastor Elvis Dumitru</h5>
        </v-col>
        <v-col cols="12" lg="4">
          <v-img contain max-width="400" src="@/assets/images/PastorElvis.jpg" />
          <h4 class="h4 my-4">My Favorite Bible Passage:</h4>
          <h5 class="h5 my-4">When you pass through the waters, I will be with you; And through the rivers, they shall not
            overflow you. When you walk through the fire, you shall not be burned, Nor shall flame scorch you.</h5>
          <h5 class="h5 my-4"><b>Isaiah 43:2</b></h5>
        </v-col>
      </v-row>

    </v-container>
  </v-card>
</template>
<script>
export default {
  data: () => ({})
}
</script>