<template>
  <div>
    <about-bar></about-bar>
    <v-parallax
      dark
      :src="imgSrc"
      height="300"
    >
      <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            class="text-center"
            cols="12"
          >
            <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-1']" class="mb-4">
              SUDBURY SDA CHURCH HISTORY
            </h1>
          </v-col>
        </v-row>
      </v-overlay>
    </v-parallax>
    <about-history></about-history>
  </div>
</template>

<script>
import AboutBar from '@/components/AboutBar.vue'
import TopParallax from '@/components/TopParallax.vue'
import AboutHistory from '@/components/AboutHistory.vue'

export default {
  components: {
    AboutBar,
    AboutHistory
  },
  data() {
    return {
      imgSrc: require('@/assets/images/chistory_banner.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'History'
      }
    }
  }
}
</script>
