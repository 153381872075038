<template>
  <v-card rounded="0">
    <v-container>
      <h5 class="h5 my-6">In 1931 – over 75 years ago, Mrs. Mable Stitt was the only Seventh-day Adventist in Sudbury, Ontario and had a burning desire to share her faith. She requested the brethrens in Oshawa, to hold tent meetings in her town. The brethren put up a tent and held Evangelical meetings on the corner of Elm and Lorne Streets opposite the cenotaph. A church was established with approximately 50 members.</h5>
      <h5 class="h5 my-4">For the first 32 years the members met in various halls in the city and for 10 years met in the Moose Hall on Pine St. With a population of over 80,000, and a small membership of less then 30, the need of a church building for God’s remnant people in this city was very apparent. In the summer of 1963 a sale of a former Presbyterian Church building of only seven years old, a brick and stone construction and located in a very central and representative residential area of the city became available and the membership purchased that property and burnt the mortgage on Nov. 1 1969.</h5>
      <h5 class="h5 my-4">Many evangelistic meetings have been attended over the years by thousands of people, as Sudbury has grown to a large and modern city. In 1979, Pastor Laverne Schlehuber and family, moved to Sudbury and saw the need of a larger church, and with their courage and energy, set out to locate a new sanctuary, and found one at 835 Churchill Street in New Sudbury available. We altered the previously owned church with rooms for various children’s divisions and gave a face lift with new pews, carpets, piano, organ, TV and projector etc.</h5>
      <h5 class="h5 my-6">On Nov. 28, 1981 eight months after moving into the larger and better equipped building the Sudbury SDA congregation held its dedication and 50th anniversary services. Some of the events that has happened at the church has been : an Elementary school for 9 years, Several Stop Smoking Seminars including 2-5-4 smoke no more, Natural Vegetarian cooking classes, a Supper Club followed by a Natural Lifestyle Cooking by authors Mark and Ernestine Finley, Stress Control Clinic, also held Chip (Coronary Health Improvement Project) clinics, sent a container to Zambia filled with mission supplies on behalf of Adra, at Christmas and various times in the year supplied food hampers to the less fortunate, held Adra Community Campaigns once a year in the fall, organized several campmeetings at Camp Noronto, at Monetville, Ontario, a rented facility, Pathfinders was well attended (young peoples program ages 10 to 15 years) from 1985 to 1994, we have also participated in the Santa Claus Parade several times, several lawn & flea markets were organized by the church people over many years and thousands of dollars was realized, Prophecy Seminars almost yearly have been attended by the members and community as while as Evangelism outreaches. Womens and Mens and Family Ministries, all members have been very activate with Sabbath Services, friendship teas and club meetings, we have also held Youth Rallies for the SDA northern churches.</h5>
      <h4 class="h4">Pastors in the Past 75 Years</h4>
      <v-card
        class=""
        max-width="350"
        tile
      >
        <v-list dense>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.year"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ml-4" v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <h5 class="h5 my-4">We have had faithful colporteurs spreading and selling.books and literature in the Sudbury District since our church was established in 1931, also in recent years had Canadian Youth Challenge Program in the summer months. Bible workers have also being a part of spreading the word. In 1982 Pastor Heintz presented our believes on Coumunity Channel for several months.</h5>
      <h5 class="h5 my-4">Many people have left Sudbury in search of employment and to further their education and other SDA churches have risen in new locations. Our church has held many baptisms and baby dedications since its beginnings in 1931 to the Glory of God. May our church continue to be a light house in Sudbury, Ontario in 2008, over 75 years since our initial evangelism.</h5>
      <hr class="mb-4">
      <v-row justify="center" class="my-4">
        <a href="/images/Aug_30_1931.jpg">
          <v-img contain max-width="400" src="@/assets/images/Aug_30_1931.jpg" />
        </a>
      </v-row>
      <h5 class="h5 text-center">August 30, 1931</h5>
      <v-row justify="center" class="my-4">
        <v-img contain max-width="400" src="@/assets/images/Tent_Meeting_Summer_1931.jpg" />
      </v-row>
      <h5 class="h5 text-center">Tent Meeting, Summer 1931</h5>
      <v-row justify="center" class="my-4">
        <v-img contain max-width="400" src="@/assets/images/First_Picnic_Aug_1932.jpg" />
      </v-row>
      <h5 class="h5 text-center">First Picnic, August 1932</h5>
      <v-row justify="center" class="my-4">
        <v-img contain max-width="400" src="@/assets/images/Dedication_Nov_28_1981.jpg" />
      </v-row>
      <h5 class="h5 text-center mb-4">Church Dedication, November 28, 1981</h5>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    selectedItem: 0,
    items: [
      { text: 'Elder M.N. Campbell', year: '1931' },
      { text: 'Elder W. Dessian', year: '1932' },
      { text: 'Elder Wilson', year: '1933' },
      { text: 'Pastor Balharrie', year: '1939' },
      { text: 'Pastor W. Rick', year: '1942' },
      { text: 'Pastor Brewer', year: '1949' },
      { text: 'Pastor M. Fisher', year: '1952' },
      { text: 'Pastor O. Libby', year: '1953' },
      { text: 'Pastor A. Blair', year: '1956' },
      { text: 'Pastor Wesley Brown', year: '1961' },
      { text: 'Pastor C.R. Neil', year: '1962' },
      { text: 'Pastor M. Mercer', year: '1963' },
      { text: 'Pastor F.C.J. Pearce', year: '1967' },
      { text: 'Pastor Gallant', year: '1970' },
      { text: 'Pastor O. Orpana', year: '1972' },
      { text: 'Pastor S. Villeneuve', year: '1976' },
      { text: 'Pastor L. Schlehuber', year: '1979' },
      { text: 'Pastor D. Heintz', year: '1982' },
      { text: 'Pastor H. Sormin', year: '1985' },
      { text: 'Pastor R. Parent', year: '1991' },
      { text: 'Pastor A. Marttenen', year: '1994' },
      { text: 'Pastor Luc Sabot', year: '2000' },
      { text: 'Pastor Jake Ivanovic', year: '2005' }
    ]
  })
}
</script>